<template>
  <div class="account-page">
    <esmp-tabs
      v-model="currentTabName"
      :animated="false"
    >
      <esmp-tab-pane
        v-for="tab in tabs"
        :key="`tab_${tab.name}`"
        :label="tab.label"
        :name="tab.name"
        :counter="tabCounters[tab.name]"
        :disabled="tabDisabling[tab.name]"
      />
    </esmp-tabs>

    <router-view />
    <esmp-button
      class="account-page__logout"
      size="large"
      icon="logout"
      icon-position="left"
      @click="logout"
    >
      Выйти
    </esmp-button>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Hub from '@/plugins/event-hub';

export default {
  name: 'Account',

  data() {
    return {
      tabs: [
        {
          label: 'Мои данные',
          name: 'my-info',
          disabled: false,
        },
        {
          label: 'Мои шаблоны',
          name: 'my-templates',
          disabled: true, // по умолчанию таб неактивен
        },
        {
          label: 'Мои избранные',
          name: 'my-favorites',
          disabled: true, // по умолчанию таб неактивен
        },
      ],
    };
  },

  computed: {
    ...mapState('userTemplates', ['templates']),
    ...mapState('services', ['favoriteServices']),

    tabCounters() {
      return {
        'my-templates': this.templates.length,
        'my-favorites': this.favoriteServices?.length || 0,
      };
    },

    tabDisabling() {
      return {
        'my-templates': !this.templates.length,
        'my-favorites': !this.favoriteServices?.length,
      };
    },

    currentTabName: {
      get() {
        const { path } = this.$route;

        return path.replace('/account/', '');
      },

      set(value) {
        const route = `/account/${value}`;

        if (this.$route.path !== route) {
          this.$router.push(`/account/${value}`);
        }
      },
    },
  },

  created() {
    // Каждый раз при входе на страницу личного кабинета получать
    // свежие данные о шаблонах и избранных услугах
    this.getUserTemplates();
    this.getFavorites();

    Hub.$on('account-my-templates-changed', this.getUserTemplates);
  },

  beforeDestroy() {
    Hub.$off('account-my-templates-changed', this.getUserTemplates);
  },

  methods: {
    ...mapActions('userTemplates', ['getUserTemplates']),
    ...mapActions('services', ['getFavorites']),
    ...mapActions('auth', ['logout']),
  },
};
</script>

<style lang="scss">
.account-page {
  @include for-size(phone-portrait-down) {
    padding-top: 20px;
  }

  &__logout {
    display: none;
    @include for-size(phone-portrait-down) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      background: rgba(148, 102, 255, 0.1);
      color: #9466FF;
    }
  }
}
.account-page-tab {
  &__title {
    margin-bottom: 22px;

    @include for-size(phone-portrait-down) {
      margin-bottom: 16px;
    }
  }

  &__pagination {
    padding: math.div($gap, 2) 0;
    display: flex;
    justify-content: center;
  }

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 22px;

    @include for-size(phone-portrait-down) {
      margin-bottom: 16px;
    }
  }

  &__settings {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__settings-hint {
    margin-top: 12px;
  }

  &__item {
    transition:
      width $base-animation,
      margin-left $base-animation,
      background-color $base-animation,
      color $base-animation !important;

    &:before {
      content: '=';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -$draggable-element-width;
      font-size: 26px;
      visibility: hidden;
      opacity: 0;
      transition: opacity $base-animation;
    }
    &--draggable {
      width: calc(100% - #{$draggable-element-width});
      margin-left: $draggable-element-width;
      position: relative;
      cursor: move !important;

      &:before {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
</style>
